const scrollToTop = () => {
  const mainElement = document.querySelector('main');

  if (mainElement) {
    mainElement.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Adds smooth scrolling behavior
    });
  }
};

const scrollToTopOfParent = () => {
  if (window.parent) {
    window.parent.postMessage('scrollToTop', '*');
  }
};

export { scrollToTop, scrollToTopOfParent };
