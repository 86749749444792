import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LogoComponent = () => {
  const [logoInfo, setLogoInfo] = useState({});

	const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    // Make an API request to fetch the logo URL
    axios.get(`${apiEndpoint}/client`)
      .then(response => {
        // Assuming the logo URL is in the 'url' field of the response data
        const { name, logoUrl } = response.data;
				
				if (process.env.NODE_ENV === 'development')
				{
					setLogoInfo({ name, logoUrl: apiEndpoint + '/..' + logoUrl });
				}
				else
				{
					setLogoInfo({ name, logoUrl });
				}
      })
      .catch(error => {
        console.error('Error fetching logo:', error);
      });
  }, [apiEndpoint]);

  return (
    <div>
      {logoInfo.name && (
        <div>
          {logoInfo.logoUrl && (
            <a href="/"><img src={logoInfo.logoUrl} title={logoInfo.name} alt={logoInfo.name} style={{height: '100px'}} /></a>
          )}
        </div>
      )}
    </div>
  );
};

export default LogoComponent;
