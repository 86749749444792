import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, Link } from 'react-router-dom';

import {scrollToTop, scrollToTopOfParent} from '../components/scrollUtils'; 
import MyGallery from '../components/MyGallery';
import EmailButton from '../components/EmailButton';

const ViewObject = () => {
  const [item, setItem] = useState({});
	const [assets, setAssets] = useState([]);
	const [relatedObjects, setRelatedObjects] = useState([]);
	const { id } = useParams();
	const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
	const [loading, setLoading] = useState(false);
	const [showAllKeywords, setShowAllKeywords] = useState(false);

	const recipientEmail = 'Jennifer.Hillyard@thecommonroom.org.uk';
	
	const toggleShowAllKeywords = () => {
    setShowAllKeywords(!showAllKeywords);
  };

	useEffect(() => {
    setLoading(true);

		// Function to fetch item details from the backend endpoint based on the 'id'
    const fetchItemDetails = async () => {
      try {
        const response = await fetch(`${apiEndpoint}/viewObject/${id}`);
        if (response.ok) {
          const json = await response.json();
          setItem(json.object); // Set the fetched item details to the state
					setAssets(json.assets);
					setRelatedObjects(json.related_objects);

					scrollToTop();
					scrollToTopOfParent();
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching item details:', error);
        // Handle errors or set default values for item details state if needed
			} finally {
				setLoading(false); // Set loading to false after API call completes
			}
    };

    // Call the fetchItemDetails function when the component mounts
    fetchItemDetails();
  }, [id, apiEndpoint]); // Re-run the effect when the 'id' parameter changes

	let category_label = '';

	return (
		<>
			<Helmet>
				<title>{item.title}</title>
				<meta name="description" content={item.title} />
			</Helmet>

			{loading ? (
				<div className="bg-white max-w-screen-2xl mx-auto px-4">
					<div className="loader-container">
						<div className="loader"></div>
					</div>
				</div>
			) : (
				<div>

					<div className="bg-black px-4">

						<div className="max-w-screen-2xl mx-auto grid md:grid-cols-2 gap-4 w-full p-5 py-10">
							<div className="col-span-1">
								<div className="my-2">
									<span className="border text-gray-400 uppercase text-xs py-0.5 px-2 font-semibold">ID</span>
									<span className="ml-2 text-gray-400">{item.identifier}</span>
								</div>
								
								<div className="text-sm mb-4 text-white">
									{item.categories &&
										item.categories.length > 0 &&
										item.categories.map((category, index) => {

											if (index > 0)
											{
												category_label += '/';
											}
											category_label += category.name;

											return (
												<React.Fragment key={index}>
													<Link
														to={`/app/?category=${category.id}&category_label=${encodeURIComponent(category_label)}`}
														className="underline"
													>
														{category.name}
													</Link>
													{index < item.categories.length - 1 ? <span className="mx-1">/</span> : null}
												</React.Fragment>
											);
										})}

								</div>

								<h1 className="text-3xl text-white font-semibold mt-10 mb-8">
									{item.title}{' '}
									{item.page_range ? (
										<span>({item.page_range})</span>
									) : (
										null // Render nothing if page_range doesn't exist
									)}
								</h1>

								<div className="my-2 text-white">
									<span className="border uppercase text-xs py-0.5 px-2 font-semibold">DATE</span>
									<span className="ml-2">{item.date_from_uk_format ? item.date_from_uk_format : ''}</span>
									<span className="mx-1">—</span>
									<span>{item.date_to_uk_format ? item.date_to_uk_format : ''}</span>
								</div>
								
								<EmailButton
									buttonText="Request a Copy"
									recipientEmail={recipientEmail}
									subject="Request a Copy"
									identifier={item.identifier}
								/>
								<EmailButton
									buttonText="Request a Visit"
									recipientEmail={recipientEmail}
									subject="Request a Visit"
									identifier={item.identifier}
								/>
							</div>
							<div className="grid gap-4">

								<MyGallery slides={assets} />

							</div>
						</div>
					</div>
					<div className="bg-white max-w-screen-2xl mx-auto px-4">

						<div className="grid md:grid-cols-2 gap-4 w-full my-10 max-w-screen-xl">

							<div>

								<h4 className="text-xl font-semibold mb-4">Full description</h4>
								<div className="mt-8 my-4" dangerouslySetInnerHTML={{ __html: item.description }} />

							</div>
							<div className="">
								<div className="mb-4 bg-gray-50 max-w-screen-sm p-4 font-semibold">
									<h4 className="text-xl font-semibold mb-4">Further details</h4>

									<div className="uppercase text-gray-400 text-sm">Dimensions</div>
									<div className="text-black text-md border-b border-white pb-2 mb-2">{item.dimensions ? item.dimensions : 'NA'}</div>

									<div className="uppercase text-gray-400 text-sm">Language</div>
									<div className="text-black text-md border-b border-white pb-2 mb-2">{item.language}</div>

									<div className="uppercase text-gray-400 text-sm">From date</div>
									<div className="text-black text-md mb-2">{item.date_from_uk_format ? item.date_from_uk_format : 'NA'}</div>

									<div className="uppercase text-gray-400 text-sm">To date</div>
									<div className="text-black text-md mb-2">{item.date_to_uk_format ? item.date_to_uk_format : 'NA'}</div>

									<div className="uppercase text-gray-400 text-sm">Classmark</div>
									<div className="text-black text-md mb-2">{item.classmark ? item.classmark : 'NA'}</div>

									<div className="uppercase text-gray-400 text-sm">Previous identifier</div>
									<div className="text-black text-md mb-2">{item.previous_identifier}</div>
									
									<>
										<div className="uppercase text-gray-400 text-sm">Keywords</div>
										{item.keywords && item.keywords.length > 0 ? (
											<>
												<div className="text-black text-md mb-2">
													{showAllKeywords
														? item.keywords.map((keyword, index) => (
																<span key={index}>
																	{keyword}
																	{index !== item.keywords.length - 1 ? ', ' : ''}
																</span>
															))
														: item.keywords.slice(0, 10).map((keyword, index) => (
																<span key={index}>
																	{keyword}
																	{index !== item.keywords.length - 1 ? ', ' : ''}
																</span>
															))}
												</div>
												{item.keywords.length > 10 && (
													<div>
														<button onClick={toggleShowAllKeywords} className="underline cursor-pointer">
															{showAllKeywords ? 'Show Less' : 'Show All'}
														</button>
													</div>
												)}
											</>
										) : (
											<div className="text-black text-md mb-2">No keywords available</div>
										)}
									</>

								</div>
							</div>
						</div>

					</div>

					{relatedObjects && (relatedObjects.length > 0) && (

						<div className="bg-white border-t-2 mt-8 pt-2 border-gray-100 max-w-screen-2xl mx-auto px-4">
							<div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full my-10 max-w-screen-xl">
								<div className="items-center justify-center mb-5 md:col-span-1 col-span-2">
									<h2 className="text-2xl font-semibold">Related Objects</h2>
								</div>

								{relatedObjects && (relatedObjects.length > 0) && relatedObjects.map((object, index) => (
										<div className="items-center shadow justify-center mb-5" key={index}>
											<Link to={'/app/object/' + object.id} className="items-center shadow justify-center mb-5" key={index}>
												{object.featured_asset_thumbnail && (
													<img src={object.featured_asset_thumbnail} className="mb-4" alt={object.title} title={object.title} />
												)}
												<div className="p-4">
													<div className="text-lg">{object.title}</div>
													<div className="text-sm text-gray-400 mt-1">{object.identifier}</div>
												</div>
											</Link>
										</div>
									))}
							</div>
						</div>

					)}

			</div>
		)}

		</>
  );
};

export default ViewObject;
