import React, { useState, useEffect, useCallback } from 'react';

import { Helmet } from 'react-helmet-async';

import { useNavigate, useLocation, Link } from 'react-router-dom';

import axios from 'axios';
import CategoryAutosuggest from '../components/CategoryAutosuggest';
import {scrollToTop, scrollToTopOfParent} from '../components/scrollUtils'; 
import ToggleSwitch from '../components/ToggleSwitch';
import LanguageSelector from '../components/LanguageSelector';
import ImageLoader from '../components/ImageLoader';

import placeholderImage from '../assets/loading.svg';

const Home = () => {
	
	const navigate = useNavigate();
  const location = useLocation();

	const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

	const [objects, setObjects] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
	const [totalResults, setTotalResults] = useState(0);
	const [resetFlag, setResetFlag] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null); // State to store selected value
	const [selectedCategoryLabel, setSelectedCategoryLabel] = useState(null); // State to store selected value
	const [loading, setLoading] = useState(false);
	//const placeholderImage = null; //https://thecommonroom.corals.photos/a/img/nymbol/tcr-logo.svg';

	const initialToggleStates = {
    has_image: false,
    scanned: false,
  };
	const [toggleStates, setToggleStates] = useState({ ...initialToggleStates });
	
	const [selectedLanguage, setSelectedLanguage] = useState('');

	const initialFormState = {
		keyword: '',
		category: '',
		category_label: '',
		date_from: '',
		date_to: '',
		tag: '',
		has_image: false,
		scanned: false,
		language: '',
		page: 1,
	};
	const [formData, setFormData] = useState(initialFormState);

	const fetchObjects = useCallback(async (formData) => {
		setLoading(true);

		try {
			const response = await axios.get(`${apiEndpoint}/objects?page=${currentPage}`, {
				params: { ...formData }
			});

			setObjects(response.data.objects);
			setTotalPages(parseInt(response.data.num_pages, 10));
			setTotalResults(parseInt(response.data.total_results, 10));
			setResetFlag(false);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	}, [apiEndpoint, currentPage, setObjects, setTotalPages, setTotalResults, setResetFlag, setLoading]);

	// Handle form fields changes.
	const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

	const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    fetchObjects(formData);

		// Update query string.
		const searchParams = new URLSearchParams();
    Object.keys(formData).forEach((key) => {
      searchParams.set(key, formData[key]);
    });
		searchParams.set('page', 1);
    navigate(`?${searchParams.toString()}`);
  };

	const handleReset = (event) => {
		event.preventDefault();
    setFormData(initialFormState); // Reset form fields to initial state
		navigate(location.pathname);
    setCurrentPage(1); // Reset to first page on new search
		setResetFlag(true); // Set reset flag to trigger data fetching
  };

  const handleYearChange = (event, fieldName) => {
    let inputYear = event.target.value.replace(/\D-/g, '');
    if (inputYear.length > 0 && inputYear.charAt(0) === '-') { // Allow 5 characters for negative numbers (includes the '-' sign)
      inputYear = `-${inputYear.substring(1, 5)}`;
    } else {
      inputYear = inputYear.substring(0, 4); // Limit positive numbers to 4 characters
    }
		setFormData({ ...formData, [fieldName]: inputYear });
  };

	// Page navigation.
	const nextPage = () => {
		const newPage = parseInt(currentPage, 10) + 1;

		if (newPage <= totalPages) {
			setCurrentPage(newPage);
			const searchParams = new URLSearchParams();
			Object.keys(formData).forEach((key) => {
				searchParams.set(key, formData[key]);
			});
			searchParams.set('page', newPage);
			navigate(`?${searchParams.toString()}`);
			scrollToTop();
			scrollToTopOfParent();
		}
		
  };
  const prevPage = () => {
		const newPage = parseInt(currentPage, 10) - 1;

		if (newPage >= 1) {
			setCurrentPage(newPage);

			const searchParams = new URLSearchParams();
			Object.keys(formData).forEach((key) => {
				searchParams.set(key, formData[key]);
			});
			searchParams.set('page', newPage);
			navigate(`?${searchParams.toString()}`);
			scrollToTop();
			scrollToTopOfParent();
		}
  };

	const handleToggle = (name, newState) => {
    //console.log(`Toggle ${name} state:`, newState);
    setFormData({ ...formData, [name]: newState });
    setToggleStates({
      ...toggleStates,
      [name]: newState,
    });
  };

	// The category changes
	useEffect(() => {
    if (selectedCategory !== null) {

      setFormData(prevFormData => ({
        ...prevFormData, // Keep existing formData
        category: selectedCategory
      }));
    }
  }, [selectedCategory]);

	useEffect(() => {
    if (selectedCategoryLabel !== null) {

      setFormData(prevFormData => ({
        ...prevFormData, // Keep existing formData
        category_label: selectedCategoryLabel
      }));
    }
  }, [selectedCategoryLabel]);

	// Function to update formData with the selected language
  const handleLanguageChange = (selectedLang) => {
    setFormData({
      ...formData,
      language: selectedLang,
    });
  };

	useEffect(() => {
    scrollToTop();
		scrollToTopOfParent();
  }, []);

	// The important call to populate the objects initially
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const savedKeyword = searchParams.get('keyword') || '';
    const savedCategory = searchParams.get('category') || '';
		const savedCategoryLabel = searchParams.get('category_label') || '';
    const savedDateFrom = searchParams.get('date_from') || '';
    const savedDateTo = searchParams.get('date_to') || '';
    const savedTag = searchParams.get('tag') || '';
		const savedHasImage = searchParams.get('has_image') === 'true' || false;
		const savedScanned = searchParams.get('scanned') === 'true' || false;
		const savedLanguage = searchParams.get('language') || '';
    const savedPage = parseInt(searchParams.get('page')) || 1;

		let paramFormData = {
      keyword: savedKeyword,
      category: savedCategory,
      category_label: savedCategoryLabel,
			date_from: savedDateFrom,
			date_to: savedDateTo,
			tag: savedTag,
			has_image: savedHasImage,
			scanned: savedScanned,
			language: savedLanguage,
			page: savedPage,
    };

		let paramToggleStates = {
			has_image: savedHasImage,
			scanned: savedScanned,
    };

		setToggleStates({ ...paramToggleStates });

    setFormData(paramFormData);
		setCurrentPage(savedPage);
		setSelectedCategoryLabel(savedCategoryLabel);
		setSelectedLanguage(savedLanguage);

		fetchObjects(paramFormData);
  }, [fetchObjects, location.search, resetFlag, currentPage]); // Fetch data when currentPage changes


  return (
    <>
			<Helmet>
				<title>Search the archives - The Common Room</title>
				<meta name="description" content="Search the archives - The Common Room" />
			</Helmet>
			<div className="mb-5 grid grid-cols-1 md:grid-cols-4 gap-4 w-full max-w-screen-2xl mx-auto">
				<div className=" pt-8 md:h-full md:col-span-1 p-3 pb-8" /*style={{
						backgroundImage: 'linear-gradient(to left, #f5f5f5 0%, white 100%)',
						backgroundSize: '40px 100%',
						backgroundPosition: 'right',
						backgroundRepeat: 'no-repeat'
					}}*/>
					<h3 className="text-3xl font-semibold mb-4">Filters</h3>
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<label className="mb-0">Keyword</label>
							<input
								type="text"
								id="keyword"
								name="keyword"
								placeholder=""
								className="w-full border rounded-md py-2 px-3 text-gray-700 leading-tight mb-1 focus:outline-none focus:shadow-outline"
								value={formData.keyword}
								onChange={handleInputChange}
							/>
						</div>
						<div className="mb-3">
							<label className="mb-0">Category</label>
							<CategoryAutosuggest setSelectedCategoryLabel={setSelectedCategoryLabel} setSelectedCategory={setSelectedCategory} resetSelectedValue={resetFlag} />

							{selectedCategoryLabel && (
								<div className="mt-1 mb-4 font-xs"><span className="text-gray-400">Filter:</span> {selectedCategoryLabel}</div>
							)}

						</div>
						<div className="grid grid-cols-2 mb-6">
							<div className="mr-2">
								<label className="mb-0">Date from</label>
								<input
									type="number"
									id="date_from"
									min="-9999"
									max="9999"
									name="date_from"
									placeholder="yyyy"
									className="w-full border rounded-md py-2 px-3 text-gray-700 leading-tight mb-1 focus:outline-none focus:shadow-outline"
									onChange={(event) => handleYearChange(event, 'date_from')}
									value={formData.date_from}
								/>
							</div>
							<div>
								<label className="mb-0">Date to</label>
								<input
									type="number"
									id="date_to"
									name="date_to"
									min="-9999"
									max="9999"
									placeholder="yyyy"
									className="w-full border rounded-md py-2 px-3 text-gray-700 leading-tight mb-1 focus:outline-none focus:shadow-outline" 
									onChange={(event) => handleYearChange(event, 'date_to')}
									value={formData.date_to}
									/>
							</div>
						</div>
						<div className="mb-3">
							<label className="mb-0">Language</label>
							<LanguageSelector 
								initialLanguage={selectedLanguage} 
								onLanguageChange={handleLanguageChange}
							/>
						</div>
						<div className="mb-3">
							<label className="mb-0 mr-2">Has Image</label>
						
							<ToggleSwitch
								initialChecked={toggleStates.has_image}
								onToggle={(newState) => handleToggle('has_image', newState)}
								name="has_image"
								id="has_image"
							/>
							
						</div>
						<div className="mb-3">
							<label className="mb-0 mr-2">Scanned</label>
						
							<ToggleSwitch
								initialChecked={toggleStates.scanned}
								onToggle={(newState) => handleToggle('scanned', newState)}
								name="scanned"
								id="scanned"
							/>
						</div>

						<button className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 mr-2" type="submit">Filter</button>
						<button onClick={handleReset} className="ml-2 text-gray-500">
							Reset
						</button>
					</form>
				</div>
				<div className="md:col-span-3 pt-4">

					{loading ? (
						<div className="flex justify-center items-center h-full">
							<div className="loader"></div>
						</div>
					) : (
						<div>
						
							<div className="flex justify-between m-2">
								{totalPages > 0 && (
									<div className="flex">Page {currentPage} / {totalPages}</div>
								)}
								<div className="flex">{totalResults.toLocaleString()} results</div>
							</div>
							<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
								{objects && (objects.length > 0) && objects.map((object, index) => (
									<Link to={'object/' + object.id} className="items-center shadow justify-center mb-5" key={index}>
										<div>
											{object.featured_asset_thumbnail && (
												<ImageLoader src={object.featured_asset_thumbnail} alt={object.title} placeholder={placeholderImage} />
											)}
											<div className="p-4">
												<div className="text-lg">{object.title}</div>
												<div className="text-sm text-gray-400 mt-1">{object.identifier}</div>
											</div>
										</div>
									</Link>
								))}
							</div>
							<div className="mt-5 text-center">
								<button onClick={prevPage} className={`${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-500 hover:bg-gray-400'} text-white font-bold py-2 px-4 mr-2`} disabled={currentPage === 1}>Previous</button>
								<button onClick={nextPage} className={`${currentPage >= totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-500 hover:bg-gray-400'} text-white font-bold py-2 px-4 mr-2`} disabled={currentPage >= totalPages}>Next</button>
							</div>

						</div>
					)}

				</div>
			</div>
    </>
  )
};

export default Home;
