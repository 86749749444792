import React, { useState, useEffect } from 'react';

const ImageLoader = ({ src, alt, placeholder }) => {
  //const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(placeholder);

	useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      //setLoading(false);
      setImageSrc(src);
    };

    return () => {
      image.onload = null;
    };
  }, [src]);

  return (
    <img
      src={imageSrc}
      alt={alt}
			width="400"
			height="400"
			title={alt}
			className="w-full mb-4"
      //style={{ opacity: loading ? '0' : '1' }}
    />
  );
};

export default ImageLoader;
