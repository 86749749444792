import { Outlet } from "react-router-dom";
import LogoComponent from '../components/LogoComponent';

const currentYear = new Date().getFullYear();

const isRunningInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true; // Security error, assume it's an iframe
  }
};

const Layout = () => {
	const inIframe = isRunningInIframe();

  return (
    <>
			{!inIframe && (
				<header className="w-full bg-gray-100">
					<div className="max-w-screen-2xl mx-auto p-5 flex justify-between">
						<LogoComponent />
						<a href="https://thecommonroom.org.uk/collections/" className="flex items-end text-sm">Back to Common Room Website</a>
					</div>
				</header>
			)}
			<main>
				<Outlet />
			</main>
			{!inIframe && (
				<footer>
					<div className="max-w-screen-2xl mx-auto p-5 text-right">
						<p>© {currentYear} MG&amp;E Ltd. | <a href="https://corals.photos" target="_blank" rel="noreferrer">Atoll</a> brought to you by Corals.</p>
					</div>
				</footer>
			)}
    </>
  )
};

export default Layout;