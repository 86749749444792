import React from 'react';

const EmailButton = ({ recipientEmail, subject, body, buttonText, identifier }) => {
  const createMailtoLink = () => {
    const emailSubject = encodeURIComponent(subject+' - '+identifier || 'Subject');

		const mailtoLink = `mailto:${recipientEmail}?subject=${emailSubject}`;
    window.location.href = mailtoLink;
  };

  return (
    <button className="mt-4 bg-black hover:bg-gray-700 border text-black shadow-xs py-2 px-4 text-white mr-2" onClick={createMailtoLink}>
      {buttonText}
    </button>
  );
};

export default EmailButton;
