import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";


const MyGallery = ({ slides }) => {
  const [open, setOpen] = React.useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = React.useState(0);

	const openLightboxOnSlide = (index) => {
    setInitialSlideIndex(index);
    setOpen(true);
  };

	const zoomRef = React.useRef(null);

  return (
    <>

			<div className="grid grid-cols-2 gap-4 h-full items-center justify-center">
  {slides &&
    slides.length > 0 &&
    slides.map((slide, index) => (
      <div key={index}>
        {slide.thumb && (
          <>
            {slide.thumb.endsWith('.pdf') ? (
              <div>
                {/* Display PDF download icon */}
               <a href={slide.download_url} download className="text-white" style={{ display: 'flex', alignItems: 'center' }}>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
  </svg>
  <span>Download PDF</span>
</a>


              </div>
            ) : (
              <>
                {/* Display thumbnail image */}
                <button
                  className="cursor"
                  onClick={() => {
                    openLightboxOnSlide(index);
                  }}
                >
                  <img
                    src={slide.thumb}
                    className="mb-4"
                    alt={slide.alt}
                    title={slide.alt}
                    width="w-full"
                  />
                  <div>{slide.file_size}</div>
                </button>
              </>
            )}
          </>
        )}
      </div>
    ))}
</div>


      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides}
				index={initialSlideIndex}
				plugins={[Zoom]}
				zoom={{ ref: zoomRef }}
				inline={{
					style: { width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" },
				}}
      />

    </>
  );
};

export default MyGallery;
