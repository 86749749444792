import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const CategoryAutosuggest = ({ setSelectedCategoryLabel, setSelectedCategory, resetSelectedValue }) => {

	const loadOptions = async (inputValue, callback) => {
		try {
			const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
			const response = await axios.get(`${apiEndpoint}/autocomplete?q=${inputValue}`);
			if (response.data.length)
			{
				const options = response.data.map(item => ({
					value: item.id,
					label: item.name
				}));
				callback(options);
			}
			
		} catch (error) {
			console.error('Error fetching options:', error);
			callback([]);
		}
	};

  const [selectedOption, setSelectedOption] = useState(null);
	const [defaultOptions, setDefaultOptions] = useState([]);

  const handleChange = selectedOption => {
		const { value } = selectedOption || {};
		setSelectedOption(selectedOption);
		setSelectedCategory(value);
		setSelectedCategoryLabel(selectedOption.label);
  };

	useEffect(() => {
    // Handle resetSelectedValue prop changes
    if (resetSelectedValue) {
      setSelectedOption(null); // Reset the selectedOption state
    }
  }, [resetSelectedValue]);

	useEffect(() => {
    const fetchDefaultOptions = async () => {
      try {
        const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
        const response = await axios.get(`${apiEndpoint}/autocomplete`);

        if (response.data.length) {

          // Transform the data into the format react-select expects (label and value properties)
          const options = response.data.map(item => ({ label: item.name, value: item.id }));

          // Set the default options state
          setDefaultOptions(options);
        }
      } catch (error) {
        console.error('Error fetching default options:', error);
      }
    };

    // Fetch default options when the component mounts
    fetchDefaultOptions();
  }, []);

  return (
    <div>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        onChange={handleChange}
				defaultOptions={defaultOptions}
        value={selectedOption}
        placeholder="Start typing..."
      />
    </div>
  );
};

export default CategoryAutosuggest;
