import React, { useState, useEffect } from 'react';

const ToggleSwitch = ({ onToggle, initialChecked = false, name, id }) => {
  const [checked, setChecked] = useState(initialChecked);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const handleToggle = () => {
    const newState = !checked;
    setChecked(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleToggle}
        name={name}
        id={id}
      />
      <span className="switch" />
    </label>
  );
};

export default ToggleSwitch;
