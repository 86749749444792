import React, { useState, useEffect } from 'react';

const LanguageSelector = ({ initialLanguage, onLanguageChange }) => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
		const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
		//console.log( `${apiEndpoint}/languages` );
    fetch(`${apiEndpoint}/languages`) // Replace with your API endpoint
      .then((response) => response.json())
      .then((data) => {
        setLanguages(data); // Update state with fetched languages
				setSelectedLanguage(initialLanguage);
      })
      .catch((error) => {
        console.error('Error fetching languages:', error);
      });
  }, [initialLanguage]);

  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value;
    setSelectedLanguage(selectedLang);
    // Invoke the callback function from the parent component
    onLanguageChange(selectedLang);
  };

  return (
		<div className="relative inline-block text-left w-full">
			<select
				value={selectedLanguage}
				onChange={handleLanguageChange}
				className="block appearance-none w-full bg-white border border-gray-300 py-2 px-4 rounded leading-tight focus:outline-none focus:border-blue-500"
			>
				<option value="">-- Any --</option>
				{languages.map((language) => (
					<option key={language.id} value={language.id}>
						{language.name}
					</option>
				))}
			</select>
			<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
				<svg
					className="fill-current h-4 w-4"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
				>
					<path
						d="M7 7l3-3 3 3m0 6l-3 3-3-3"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
  );
};

export default LanguageSelector;
