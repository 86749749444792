//import ReactDOM from "react-dom/client";
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import "./assets/index.css"

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import ViewObject from "./pages/ViewObject";
import NoPage from "./pages/NoPage";

export default function App() {

	useEffect(() => {
    const handleContentHeightChange = () => {
      const contentHeight = document.body.scrollHeight;
			//console.log('contentHeight: '+contentHeight);
      window.parent.postMessage({ height: contentHeight }, '*');
    };

    // Monitor changes in content height at regular intervals (you may adjust the interval as needed)
    const interval = setInterval(handleContentHeightChange, 1000); // Check every 500ms

    return () => {
      clearInterval(interval); // Clear the interval when component unmounts
    };
  }, []);

  return (
    <HelmetProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/app/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="object/:id" element={<ViewObject />} />
						<Route path="*" element={<NoPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</HelmetProvider>
  );
}
